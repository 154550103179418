import React, { useState, useEffect, useRef, useContext } from "react";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import {
  Divider,
  Stack,
  CircularProgress,
  IconButton,
  SvgIcon,
} from "@mui/material";
import { ChevronLeft as LeftArrowIcon } from "react-feather";
import Page from "src/components/common/Page";
import RecentThreads from "src/components/Chat/RecentThreads";
import Thread from "src/components/Chat/Thread";
import useDeviceBreakpoint from "src/utils/useDeviceBreakpoint";
import { ChatContext } from "src/context/ChatContext";
import _ from "lodash";
import Search from "src/components/Chat/Search";
import Filter from "src/components/Chat/Filter";
import SearchMessageResults from "src/components/Chat/SearchMessageResults";
import { toast } from "react-toastify";

const DebouncedHandler = (handler, delay = 1000) =>
  _.debounce(handler, delay, {
    leading: true,
    trailing: false,
  });

const Chat = () => {
  const pageRef = useRef(null);
  const location = useLocation();
  const queryObj = queryString.parse(location.search);
  const history = useHistory();
  const { isMobile } = useDeviceBreakpoint();

  const {
    isLoading,
    areThreadsHidden,
    messageSearchResults,
    selectedMessage,
    setAreThreadsHidden,
    setRecipient,
    setSelectedMessage,
    resetSearch,
    handleReadMessage,
    handleGetRecipient,
    threads,
  } = useContext(ChatContext);

  const { phoneNumber: queryPhoneNumber } = queryObj || null;
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

  const handleSelectThread = async (thread) => {
    // On mobile hide threads
    if (isMobile) {
      setAreThreadsHidden(true);
    }

    const encodedPhoneNumber = encodeURIComponent(thread.phoneNumber);
    history.push(`/chat?phoneNumber=${encodedPhoneNumber}`);

    setSelectedMessage({
      id: thread.id,
      queryPhoneNumber: thread.phoneNumber,
    });
  };

  const handleSelectMessage = async (message) => {
    const { fromPhoneNumber, toPhoneNumber, createdByClient } = message;
    const clientPhoneNumber = createdByClient ? fromPhoneNumber : toPhoneNumber;

    // On mobile hide threads
    if (isMobile) {
      setAreThreadsHidden(true);
    }

    if (selectedMessage?.id !== message?.id) {
      // update the URL with the selected client's phone number

      const encodedPhoneNumber = encodeURIComponent(clientPhoneNumber);

      history.push(`/chat?phoneNumber=${encodedPhoneNumber}`);

      setSelectedMessage({
        id: message?.id,
        queryPhoneNumber: clientPhoneNumber,
      });
    }

    if (message) setRecipient(message);
  };

  const debouncedHandleSelectThread = DebouncedHandler(handleSelectThread);
  const debouncedHandleSelectMessage = DebouncedHandler(handleSelectMessage);

  useEffect(() => {
    return () => {
      resetSearch();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Each time the phoneNumber from the query changes, update the selectedPhoneNumber:
  useEffect(() => {
    if (!queryPhoneNumber) {
      setRecipient(null);
      setSelectedPhoneNumber(null);
      setSelectedMessage(null);
      return;
    }

    (async () => {
      const recipient = await handleGetRecipient(queryPhoneNumber);
      if (recipient) {
        setRecipient(recipient);
        setSelectedPhoneNumber(queryPhoneNumber);
      } else {
        setRecipient(null);
        setSelectedPhoneNumber(null);
        toast.error(`Client with phone number ${queryPhoneNumber} not found`);
      }
    })();
  }, [queryPhoneNumber, threads]);

  return (
    <>
      {/* Mobile Back Button when a user clicks on a thread */}
      {areThreadsHidden && isMobile && (
        <IconButton
          onClick={() => {
            setAreThreadsHidden(false);
            setSelectedMessage(null);
            setSelectedPhoneNumber(null);
            history.push("/chat");
          }}
          sx={{
            color: "white",
            position: "absolute",
            top: (theme) => theme.spacing(1.5), // 12px
            left: (theme) => theme.spacing(7.5), // 60px
            zIndex: 9999,
          }}
        >
          <SvgIcon>
            <LeftArrowIcon />
          </SvgIcon>
        </IconButton>
      )}
      <Page
        sx={{
          backgroundColor: "background.chat",
        }}
        title="Chat"
        ref={pageRef}
      >
        <Stack
          direction="row"
          sx={{
            overflow: "hidden",
            height: "100%",
          }}
        >
          {!areThreadsHidden && (
            <>
              <Stack
                sx={{
                  position: "relative",
                  backgroundColor: "background.default",
                  width: {
                    xs: "100%",
                    md: 300,
                  },
                  minWidth: {
                    xs: "100%",
                    md: 300,
                  },
                  height: "100%",
                }}
              >
                <Search />
                <Filter />
                <Divider />
                {isLoading && (
                  <Stack sx={{ alignItems: "center", py: 2 }}>
                    <CircularProgress />
                  </Stack>
                )}
                {messageSearchResults ? (
                  <SearchMessageResults
                    handleSelectMessage={debouncedHandleSelectMessage}
                  />
                ) : (
                  <RecentThreads
                    selectedPhoneNumber={selectedPhoneNumber}
                    handleSelectThread={debouncedHandleSelectThread}
                  />
                )}
              </Stack>
              <Divider orientation="vertical" flexItem />
            </>
          )}
          {Boolean(selectedPhoneNumber || selectedMessage?.id) && (
            <Thread selectedPhoneNumber={selectedPhoneNumber} />
          )}
        </Stack>
      </Page>
    </>
  );
};

export default Chat;
